/** @jsx jsx */
import { jsx } from 'theme-ui';

import React from 'react';
import {
  Flex,
  Box,
  Heading,
  Text,
  Link,
  Input,
  Textarea,
  Button,
  Label,
} from 'theme-ui';
import { graphql, Link as GatsbyLink } from 'gatsby';

import {
  AiOutlineInstagram,
  AiOutlineFacebook,
  AiOutlineWhatsApp,
  AiOutlineMail,
} from 'react-icons/ai';
import { Helmet } from 'react-helmet';
import { Trans, t } from '@lingui/macro';

import Breadcrumbs from '../../../plugins/storefront/src/components/Breadcrumbs';

const SendHairPhotosPage = (props) => {
  const {
    siteUrl,
    gatsbyStorefrontConfig: { storeName },
  } = props.data.site.siteMetadata;
  return (
    <>
      <Helmet
        title={t({
          id: `SendHairPhotos.Title`,
          message: `Send your hair photos`,
        })}
        titleTemplate={t`%s — ${storeName}`}
        defer={false}
      >
        <meta
          name="description"
          content={t({
            id: `SendHairPhotos.MetaDescription`,
            message: `Send your hair photos and we will We will suggest the most suitable color to buy. `,
          })}
        />
      </Helmet>
      <Flex
        my={3}
        py={[2, 3, 4]}
        sx={{
          width: '100%',
          bg: '#f4f1eb',
          justifyContent: 'center',
        }}
      >
        <Box mx={3}>
          <Breadcrumbs
            collectionTitle={t({
              id: `SendHairPhotos.BreadcrumbsParentTitle`,
              message: `Guides`,
            })}
            collectionPath={'/guides'}
            productTitle={t({
              id: `SendHairPhotos.BreadcrumbsTitle`,
              message: `Send your hair photos`,
            })}
            separator="/"
          />
        </Box>
      </Flex>

      <Box
        mx="auto"
        px={[3, 2]}
        mt={[1, 4]}
        sx={{
          width: '100%',
          maxWidth: 1300,
        }}
      >
        <Heading as="h1" sx={{ fontSize: [4, 5] }}>
          <Trans id="SendHairPhotos.Header">Send your hair photos</Trans>
        </Heading>
      </Box>

      <Flex
        columns={[1, 2]}
        mx="auto"
        px={[3, 2]}
        mb={4}
        sx={{
          width: '100%',
          maxWidth: 1300,
        }}
      >
        <Box my={[3, 4]}>
          <Trans id="SendHairPhotos.Description">
            <Text as="p" mb={[2]}>
              Take several good-quality photos and, optionally, a short video of
              your hair from the back. If you can, ask someone to help you to
              make photos.
            </Text>

            <Text as="p" mb={[2, 3]}>
              Please make sure that your photos are of good quality, made in
              natural lighting, and without image filters. Preferably take
              photos near the window or on the street.
            </Text>

            <Text as="p" mb={[2, 3]}>
              Send your photos to one of our social media accounts, messengers
              or submit a form below. We will suggest the most suitable color to
              buy.
            </Text>
            <Text as={Flex} mb={[2, 3]}>
              <Text
                as="a"
                variant="link"
                href="https://instagram.com/thekisscurls"
                pr={3}
                sx={{ textAlign: 'center' }}
              >
                <AiOutlineInstagram
                  size="40"
                  sx={{ position: 'relative', bottom: '1px' }}
                />{' '}
                Instagram
              </Text>

              {/* <Text
              as="a"
              variant="link"
              href="https://facebook.com/kisscurls"
              pr={3}
              sx={{ textAlign: 'center' }}
            >
              <AiOutlineFacebook
                size="40"
                sx={{ position: 'relative', bottom: '1px' }}
              />{' '}
              Facbook
            </Text> */}

              <Text
                as="a"
                variant="link"
                href="https://wa.me/375291833587"
                pr={3}
                sx={{ textAlign: 'center' }}
              >
                <AiOutlineWhatsApp
                  size="40"
                  sx={{ position: 'relative', bottom: '1px' }}
                />{' '}
                WhatsApp
              </Text>
            </Text>
            <Text as="p" mb={[2, 3]}></Text>
          </Trans>
          <Box sx={{ maxWidth: 600, mx: 'auto' }} mt={[3, 4]}>
            <form
              method="POST"
              action="https://formsubmit.co/faa836047349b4c22919bd05128524b8"
              encType="multipart/form-data"
            >
              <input
                type="hidden"
                name="_next"
                value={`${siteUrl}/thank-you`}
              />
              <input type="text" name="_honey" sx={{ display: 'none' }} />
              <input type="hidden" name="_captcha" value="false" />
              <Label htmlFor="name">
                <Trans id="Contact.FormName">Your name</Trans>
              </Label>
              <Input type="name" name="name" mb={[2, 3]} />
              <Label htmlFor="email">
                <Trans id="Contact.FormEmail">E-mail to contact you back</Trans>
              </Label>
              <Input type="email" name="email" mb={[2, 3]} />
              <Label htmlFor="email">
                <Trans id="Contact.FormPhone">Your phone number</Trans>
              </Label>
              <Input type="phone" name="phone" mb={[2, 3]} />
              <Label htmlFor="message">
                <Trans id="Contact.FormMessage">Message</Trans>
              </Label>
              <Textarea name="message" mb={[2, 3]}></Textarea>
              <Label>
                <Trans id="Contact.FormAttachments">
                  Attachments (for your photos and videos)
                </Trans>
              </Label>
              <Input
                type="file"
                name="attachment1"
                accept="image/png, image/jpeg, video/mp4"
                mb={[2, 3]}
              />

              <Input
                type="file"
                name="attachment2"
                accept="image/png, image/jpeg, video/mp4"
                mb={[2, 3]}
              />

              <Input
                type="file"
                name="attachment3"
                accept="image/png, image/jpeg, video/mp4"
                mb={[2, 3]}
              />

              <Input
                type="file"
                name="attachment4"
                accept="image/png, image/jpeg, video/mp4"
                mb={[2, 3]}
              />
              <Button type="submit" mb={[2, 3]} px={[4, 5]} py={[2, 3]}>
                <Trans id="Contact.FormButton">Submit form</Trans>
              </Button>
            </form>
          </Box>
        </Box>
      </Flex>
    </>
  );
};

export const SendHairPhotosPageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        gatsbyStorefrontConfig {
          storeName
        }
      }
    }
  }
`;
export default SendHairPhotosPage;
